import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { setLightboxForm } from '../actions'
import Row from '../components/page-content-row'
import HouseSeriesRow from '../components/house-series-row'
import HouseSeriesRowLinkItem from '../components/house-series-row/house-series-row-link-item'
import HalfGridRow from '../components/half-grid-row'
import PageHeading from '../components/page-heading'
import PageDescription from '../components/page-description'
import HalfGridRowItem from '../components/half-grid-row/half-grid-row-item'
import HausSeriesFloorPlan from '../components/haus-series-floor-plan'
import Floorplan from '../components/haus-series-floor-plan/Floorplan'
import HausSeries2 from '../components/svg/haus-series-link/haus-series-2'
import HausSeries1 from '../components/svg/haus-series-link/haus-series-1'

import floorplan_12B_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-12B_G.svg'
import floorplan_12B_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-12B-intent_G.svg'

import floorplan_15M2_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-2_G.svg'
import floorplan_15M2_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-2-intent_G.svg'
import floorplan_15M2_2 from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-2_L1.svg'
import floorplan_15M2_2_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-2-intent_L1.svg'

import floorplan_12E_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-12E_G.svg'
import floorplan_12E_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-12E-intent_G.svg'

import floorplan_10G_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-10G_G.svg'
import floorplan_10G_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-10G-intent_G.svg'
import floorplan_10G_2 from '../components/svg/floorplans/haus-series-3/HH-floorplan-10G_L1.svg'
import floorplan_10G_2_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-10G-intent_L1.svg'

import floorplan_1016_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-10.16_G.svg'
import floorplan_1016_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-10.16-intent_G.svg'
import floorplan_1016_2 from '../components/svg/floorplans/haus-series-3/HH-floorplan-10.16_L1.svg'
import floorplan_1016_2_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-10.16-intent_L1.svg'

import floorplan_DewarT_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-dewar_G.svg'
import floorplan_DewarT_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-dewar-intent_G.svg'

import floorplan_15m3_1 from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-3_G.svg'
import floorplan_15m3_1_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-3-intent_G.svg'
import floorplan_15m3_2 from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-3_L1.svg'
import floorplan_15m3_2_intent from '../components/svg/floorplans/haus-series-3/HH-floorplan-15m-haus-3-intent_L1.svg'

// test

const mapDispatchToProps = (dispatch) => ({})
const ogImages = [``]

const Page = ({ dispatch }) => {
  const handleEnquire = (floorplan_num) => {
    const enquireForm = {
      category: 'Enquire',
      title: 'Enquire',
      redirect: '/contact-thank-you',
      form: 'contact',
      meta: {
        haus_series_conversion_plan: floorplan_num,
      },
    }
    dispatch(setLightboxForm(enquireForm))
  }

  return (
    <>
      <SEO
        title="Haus Series 3"
        description="Start with our Haus Series, a selection of plans drawn from our experience of what makes a haus, a home."
        images={ogImages}
      />
      <Row>
        <HouseSeriesRow mb={['38px', '76px']}>
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="03"
            textColor="#3E6F42"
            bgColor="#C4D39B"
            iconRotate={90}
          />
        </HouseSeriesRow>

        {/* 12B */}
        <HausSeriesFloorPlan
          details={{
            number: '3.0',
            sqm: '241sqm',
            bedrooms: '4',
            bathrooms: '2',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan image1={floorplan_12B_1} image2={floorplan_12B_1_intent} />
        </HausSeriesFloorPlan>

        {/* 15M2 */}
        <HausSeriesFloorPlan
          details={{
            number: '3.1',
            sqm: '328sqm',
            bedrooms: '4',
            bathrooms: '3',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan
            image1={floorplan_15M2_1}
            image2={floorplan_15M2_1_intent}
          />
          <Floorplan
            image1={floorplan_15M2_2}
            image2={floorplan_15M2_2_intent}
          />
        </HausSeriesFloorPlan>

        {/* 12E */}
        <HausSeriesFloorPlan
          details={{
            number: '3.2',
            sqm: '210sqm',
            bedrooms: '3',
            bathrooms: '2',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan image1={floorplan_12E_1} image2={floorplan_12E_1_intent} />
        </HausSeriesFloorPlan>

        {/* 10 G */}
        <HausSeriesFloorPlan
          details={{
            number: '3.3',
            sqm: '340sqm',
            bedrooms: '4',
            bathrooms: '3',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan image1={floorplan_10G_1} image2={floorplan_10G_1_intent} />
          <Floorplan image1={floorplan_10G_2} image2={floorplan_10G_2_intent} />
        </HausSeriesFloorPlan>

        {/* 1016 */}
        <HausSeriesFloorPlan
          details={{
            number: '3.4',
            sqm: '340sqm',
            bedrooms: '4',
            bathrooms: '2.5',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan
            image1={floorplan_1016_1}
            image2={floorplan_1016_1_intent}
          />
          <Floorplan
            image1={floorplan_1016_2}
            image2={floorplan_1016_2_intent}
          />
        </HausSeriesFloorPlan>

        {/* DewarT */}
        <HausSeriesFloorPlan
          details={{
            number: '3.5',
            sqm: '355sqm',
            bedrooms: '4',
            bathrooms: '3',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan
            image1={floorplan_DewarT_1}
            image2={floorplan_DewarT_1_intent}
          />
        </HausSeriesFloorPlan>

        {/* 15m3 */}
        <HausSeriesFloorPlan
          details={{
            number: '3.6',
            sqm: '376sqm',
            bedrooms: '5',
            bathrooms: '3',
            garages: '2',
          }}
          mb={['42px', '68px']}
          handleEnquire={handleEnquire}
        >
          <Floorplan
            image1={floorplan_15m3_1}
            image2={floorplan_15m3_1_intent}
          />
          <Floorplan
            image1={floorplan_15m3_2}
            image2={floorplan_15m3_2_intent}
          />
        </HausSeriesFloorPlan>

        <Box px={2} mb={['34px', '68px']}>
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="01"
            textColor="#3957A5"
            bgColor="#B3DFFF"
            link="/haus-series-1"
            svg={<HausSeries1 />}
          />
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="02"
            textColor="#FF634D"
            bgColor="#FFCDB2"
            link="/haus-series-2"
            svg={<HausSeries2 />}
          />
          <HouseSeriesRowLinkItem
            heading={
              <>
                Collection <br />
                <b>Courtyards</b>
              </>
            }
            number={<Box style={{ opacity: 0 }}>.</Box>}
            textColor="#4B4B4B"
            bgColor="#CDCDC8"
            link="/courtyards"
          />
        </Box>
        <Flex flexWrap={['wrap', 'nowrap']} pb={['42px', '84px']}>
          <Box width={[1, 6 / 12]} px={2}>
            <PageHeading>Drawing from vast experience</PageHeading>
          </Box>
          <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
            <PageDescription align="left">
              <p>
                Every architect-designed plan in our series draws from our vast
                experience in South East Queensland. The designs are based on
                common block types and aim to enhance living in the subtropics.
              </p>{' '}
              <p>
                We are guided by a response to your site and apply architectural
                principles to maximise light, breezes, privacy, and views.
              </p>{' '}
              <p>
                Since 2009 we have had the pleasure of creating homes for many
                great clients in many of Australia’s amazing locations.{' '}
              </p>{' '}
              <p>
                We’re continually refining our Haus Series to be starting points
                that can be shaped into your home. Let’s start a conversation.
              </p>
            </PageDescription>
          </Box>
        </Flex>
        <HalfGridRow label="Explore">
          <HalfGridRowItem
            href="/guide-subtropical-homes"
            heading="Design Series"
            body={'Subtropical Homes Guide'}
            img="home/design-series.png"
          />
          <HalfGridRowItem
            href="/gallery"
            heading="Gallery"
            body={'New Haus Photography'}
            img="home/gallery.png"
          />
        </HalfGridRow>
      </Row>
    </>
  )
}

export default connect(mapDispatchToProps)(Page)
